var _QlResourceNodeRootQuery;

import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
const QlResourceNodeRootRequest = _QlResourceNodeRootQuery !== void 0 ? _QlResourceNodeRootQuery : _QlResourceNodeRootQuery = require("./__generated__/QlResourceNodeRootQuery.graphql");
/**
 * Used to fetch a resource node root revision id
 */

const QlResourceNodeRoot = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={QlResourceNodeRootRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlResourceNodeRoot;