import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import React, { useEffect } from "react"

import Routes, { RouteParams } from "navigators/Routes"

interface CpPatientSessionResourcecRedirectProps {
  rootRevisionId: string
  selectedRevisionId: string
}

/**
 * Redirects to a resource node in a patient session
 */
const CpPatientSessionResourcecRedirect: React.FC<CpPatientSessionResourcecRedirectProps> = ({
  rootRevisionId,
  selectedRevisionId,
}) => {
  const { navigate } = useNavigation<StackNavigationProp<RouteParams, Routes.topic>>()

  useEffect(() => {
    navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId,
    })
  }, [navigate, rootRevisionId, selectedRevisionId])

  return null
}

export default CpPatientSessionResourcecRedirect
