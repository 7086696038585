import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import CpAppLoading from "components/app/CpAppLoading";
import CpNavigationRedirect from "components/app/CpNavigationRedirect";
import CpPatientSessionNavHeader from "components/app/CpPatientSessionNavHeader";
import CpPublicNavHeader from "components/app/CpPublicNavHeader";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import ScChangeRegion from "components/screens/ScChangeRegion";
import ScNotFound from "components/screens/ScNotFound";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import imdLogo from "images/imdLogo.png";
import Routes from "navigators/Routes";
import createStackNavigatorWithHistory from "navigators/createStackNavigatorWithHistory";
import Config from "services/Config";
import translations from "translations";
const ScHelp = React.lazy(() => import("components/screens/ScHelp"));
const ScHome = React.lazy(() => import("components/screens/ScHome"));
const ScPolicies = React.lazy(() => import("components/screens/ScPolicies"));
const ScSupport = React.lazy(() => import("components/screens/ScSupport"));
const ScTermsOfUse = React.lazy(() => import("components/screens/ScTermsOfUse"));
const ScTopic = React.lazy(() => import("components/screens/ScTopic"));
const ScViewSession = React.lazy(() => import("components/screens/ScViewSession"));
const ScPartner = React.lazy(() => import("components/screens/ScPartner"));
const Stack = createStackNavigatorWithHistory();
/**
 * The public app root navigator sets up react navigation with the top-most navigator as a tab navigator
 * where the tab view is hidden on larger devices.
 */

const NvPublicRoot = () => {
  const {
    formatMessage
  } = useIntl();
  const {
    patientSessionData
  } = usePatientSessionContext();
  const {
    initialized
  } = useImdSession();
  const renderHeader = useCallback(stackHeaderProps => {
    return patientSessionData.isViewingSession ? <CpPatientSessionNavHeader {...stackHeaderProps} /> : <CpPublicNavHeader {...stackHeaderProps} />;
  }, [patientSessionData.isViewingSession]);
  const appName = formatMessage(translations.appNames[Config.APP_NAME]);
  const screenOptions = useMemo(() => ({
    header: renderHeader,
    headerMode: "float",
    // This must be float mode, or the header moves to the end of the page on screen readers
    title: formatMessage(translations.screens.app)
  }), [formatMessage, renderHeader]); // Do not render the app until imd context is initialized

  if (!initialized) return <CpAppLoading />;
  return <>
      <CpUpdatePageTitle description={translations.labels.appTagline} image={`${document.location.origin}${imdLogo}`} imageAlt={translations.labels.altImdLogo} pageType="website" title={appName} />
      <CpNavigationRedirect waitForAuth={false} />
      <Stack.Navigator screenOptions={screenOptions}>
        <Stack.Screen component={ScChangeRegion} name={Routes.change_region} options={{
        title: formatMessage(translations.screens.change_region)
      }} />
        <Stack.Screen component={ScHelp} name={Routes.help} options={{
        title: formatMessage(translations.screens.help)
      }} />
        <Stack.Screen component={ScHome} name={Routes.home} options={{
        title: formatMessage(translations.screens.home)
      }} />
        <Stack.Screen component={ScNotFound} name={Routes.not_found} options={{
        title: formatMessage(translations.screens.not_found)
      }} />
        <Stack.Screen component={ScPartner} name={Routes.partner} options={{
        title: formatMessage(translations.screens.partner),
        headerShown: false
      }} />
        <Stack.Screen component={ScPolicies} name={Routes.policies} options={{
        title: formatMessage(translations.screens.policies)
      }} />
        <Stack.Screen component={ScSupport} name={Routes.support} options={{
        title: formatMessage(translations.screens.support)
      }} />
        <Stack.Screen component={ScTermsOfUse} name={Routes.terms_of_use} options={{
        title: formatMessage(translations.screens.terms_of_use)
      }} />
        <Stack.Screen component={ScTopic} name={Routes.topic} // Fallback - title should be determined by the content being rendered
      options={{
        title: formatMessage(translations.screens.topic)
      }} />
        <Stack.Screen component={ScViewSession} name={Routes.view_session} options={{
        title: formatMessage(translations.screens.view_session)
      }} />
      </Stack.Navigator>
    </>;
};

export default NvPublicRoot;