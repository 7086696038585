import _styled from "styled-components";
import React, { useMemo } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpNotFound from "components/common/CpNotFound";
import CpInnomar from "components/psp/CpInnomar";
import CpTezspire from "components/psp/CpTezspire";
import { Partner } from "components/psp/Psp";
import { styles } from "services/Theme";

/**
 * Screen for a partner page
 */
const ScPartner = ({
  route
}) => {
  const {
    params: {
      partner
    }
  } = route;
  const renderPartner = useMemo(() => {
    switch (partner) {
      case Partner.Innomar:
        return <CpInnomar />;

      case Partner.Tezspire:
        return <CpTezspire />;

      default:
        return <CpNotFound />;
    }
  }, [partner]);
  return <_StyledSafeAreaView $_css={styles.flexSingle}>{renderPartner}</_StyledSafeAreaView>;
};

export default ScPartner;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScPartner___StyledSafeAreaView",
  componentId: "sc-1l2uak7-0"
})(["", ""], p => p.$_css);