import _styled from "styled-components";
import { cancelable } from "cancelable-promise";
import React, { useCallback, useEffect, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpError from "components/common/CpError";
import CtViewSession from "components/containers/CtViewSession";
import CpPatientSessionRedirect from "components/patientSession/CpPatientSessionRedirect";
import { PatientSessionActions, SessionType } from "components/patientSession/PatientSession";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import useAnalytics from "hooks/useAnalytics";
import useAppBackend from "hooks/useAppBackend";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import Log from "services/Log";
import SecureStorage from "services/SecureStorage";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * The screen that renders a specialized view of a Patient Session record for the patient.
 */
const ScViewSession = ({
  route
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    params: {
      isCcRecipient,
      patientId,
      patientSessionId,
      resourceNode
    }
  } = route;
  const {
    setSessionHeader
  } = useAppBackend();
  const [fetchState, setFetchState] = useState({
    busy: false,
    error: undefined,
    fetched: false
  });
  const {
    authenticate
  } = useImdSession();
  const {
    dispatchPatientSession,
    patientSessionData: {
      isViewingSession
    }
  } = usePatientSessionContext();
  const coercedIsCcRecipient = isCcRecipient === "true";
  const handleTrackGoogleEvent = useCallback(() => {
    trackGoogleEvent({
      action: "Started",
      name: "viewingSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.patientSessionId]: patientSessionId,
          [GoogleAnalyticsDimension.patientSessionType]: SessionType.ViewingSession
        }
      }
    });
  }, [trackGoogleEvent, patientSessionId]);
  useEffect(() => {
    handleTrackGoogleEvent();
  }, [handleTrackGoogleEvent]);
  useEffect(() => {
    if (patientId && patientSessionId) {
      Log.info("ScViewSession - authenticating user based on PatientSession: ", {
        patientId,
        patientSessionId
      });
      setFetchState({
        busy: true,
        fetched: false
      }); // This will be cleared out in CxPatientSession if the patient session ends

      setSessionHeader("patientSessionId", patientSessionId);
      const accessTokenPromise = cancelable(authenticate({
        identifier: patientId,
        identityType: "Identity::Patient::General",
        password: patientSessionId
      }));
      accessTokenPromise.then(async accessToken => {
        setFetchState({
          busy: false,
          fetched: true
        }); // Set session storage to ensure the accessToken doesn't get reused in other tabs effectively limiting this "authenticated" experience

        await SecureStorage.setSessionAccessToken(accessToken);
        dispatchPatientSession({
          payload: {
            isViewingSession: true,
            viewSessionParams: {
              isCcRecipient: coercedIsCcRecipient,
              patientId,
              patientSessionId
            }
          },
          type: PatientSessionActions.SetViewingParams
        });
      }).catch(error => {
        Log.error("ScViewSession - Authentication error", error);
        if (error instanceof Error) setFetchState({
          busy: false,
          error,
          fetched: true
        });
      });
      return accessTokenPromise.cancel;
    }

    return undefined;
  }, [authenticate, coercedIsCcRecipient, dispatchPatientSession, isViewingSession, patientId, patientSessionId, trackGoogleEvent, resourceNode, setSessionHeader]);

  if (resourceNode && fetchState.fetched) {
    return <CpPatientSessionRedirect resourceNode={resourceNode} />;
  }

  if (!fetchState.fetched) {
    return <PrActivityIndicator />;
  } else if (fetchState.error) {
    return <CpError error={fetchState.error} />;
  } else {
    return <_StyledSafeAreaView $_css={[styles.flexSingle, styles.screen]}>
        <CpUpdatePageTitle title={translations.screens[route.name]} />
        <CtViewSession isCcRecipient={coercedIsCcRecipient} patientSessionId={patientSessionId} />
      </_StyledSafeAreaView>;
  }
};

export default ScViewSession;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScViewSession___StyledSafeAreaView",
  componentId: "sc-ns2sar-0"
})(["", ""], p => p.$_css);