import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { useIntl } from "react-intl";
import { Image, View } from "react-native";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import FmPartnerPspPatientId from "components/psp/FmPartnerPspPatientId";
import { Partner, PatientType } from "components/psp/Psp";
import useScreenLayout from "hooks/useScreenLayout";
import { useBackendSupportedLocale } from "services/Intl";
import { css, styles } from "services/Theme";
import translations from "translations";
const AZ_ASPECT_RATIO = 450 / 200;
const FSN_ASPECT_RATIO = 200 / 133;
const LOGO_HEIGHT = 80;
const astraZenecaLogoUrl = "https://assets.imdhealth.app/assets/organizations/0331a306-d519-44fe-9dff-5b5742c1b9ba/icon_en_1534447955";
const fasenraLogoUrl = {
  en: "https://assets.imdhealth.app/assets/organizations/6593f643-8e8c-4636-a2b2-74181871e582/icon_en_1544644127",
  fr: "https://assets.imdhealth.app/assets/organizations/6593f643-8e8c-4636-a2b2-74181871e582/icon_fr_1544644128"
};
const localStyles = {
  azImage: css`
    height: ${({
    theme
  }) => theme.spacing.double + LOGO_HEIGHT}px;
    width: ${({
    theme
  }) => theme.spacing.double + LOGO_HEIGHT * AZ_ASPECT_RATIO}px;
  `,
  fsnImage: css`
    height: ${({
    theme
  }) => theme.spacing.double + LOGO_HEIGHT}px;
    width: ${({
    theme
  }) => theme.spacing.double + LOGO_HEIGHT * FSN_ASPECT_RATIO}px;
  `,
  image: css`
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  imageContainer: css`
    align-self: center;
    /* Image logo has white background, ensuring visibility and cleaning up presentation */
    background-color: white;
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    justify-content: center;
    margin-vertical: ${({
    theme
  }) => theme.spacing.double}px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    flex-direction: row;
    flex-wrap: wrap;
  `
};
/**
 * Innomar partner login page
 */

const CpInnomar = () => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const locale = useBackendSupportedLocale();
  return <>
      <CpUpdatePageTitle title="Innomar" />
      <CpBackgroundHeader childStyle={styles.flexSingle} header={formatMessage(translations.labels.patientSupportPrograms, {
      partner: "Innomar"
    })}>
        <_StyledView $_css={[styles.paddingDouble, !isMediumOrSmallerScreen ? styles.limitedLargeScreenWidth : undefined]}>
          <_StyledView2 $_css2={localStyles.imageContainer}>
            <_StyledImage accessibilityRole="image" resizeMode="contain" source={{
            uri: astraZenecaLogoUrl
          }} $_css3={[localStyles.image, localStyles.azImage]} />
            <_StyledImage2 accessibilityRole="image" resizeMode="contain" source={{
            uri: locale === "fr" ? fasenraLogoUrl.fr : fasenraLogoUrl.en
          }} $_css4={[localStyles.image, localStyles.fsnImage]} />
          </_StyledView2>

          {
          /* Sign In Form */
        }
          <FmPartnerPspPatientId partner={Partner.Innomar} patientIdMaxLength={10} patientType={PatientType.Innomar} validatorRegex={/FSN\d{7}/i} />
        </_StyledView>
      </CpBackgroundHeader>
    </>;
};

export default CpInnomar;

var _StyledView = _styled(View).withConfig({
  displayName: "CpInnomar___StyledView",
  componentId: "sc-18ixwta-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpInnomar___StyledView2",
  componentId: "sc-18ixwta-1"
})(["", ""], p => p.$_css2);

var _StyledImage = _styled(Image).withConfig({
  displayName: "CpInnomar___StyledImage",
  componentId: "sc-18ixwta-2"
})(["", ""], p => p.$_css3);

var _StyledImage2 = _styled(Image).withConfig({
  displayName: "CpInnomar___StyledImage2",
  componentId: "sc-18ixwta-3"
})(["", ""], p => p.$_css4);