import React from "react"

import CpPatientSessionResourcecRedirect from "components/patientSession/CpPatientSessionResourceRedirect"
import QlResourceNodeRoot from "query/QlResourceNodeRoot"
import { QlResourceNodeRootQuery$data } from "query/__generated__/QlResourceNodeRootQuery.graphql"

interface CpPatientSessionRedirectProps {
  resourceNode: string
}

/**
 * Handles redirection logic when we have a resource node as param
 */
const CpPatientSessionRedirect: React.FC<CpPatientSessionRedirectProps> = ({ resourceNode }) => {
  return (
    <QlResourceNodeRoot
      variables={{
        rootRevisionId: resourceNode,
        revisionId: resourceNode,
      }}
    >
      {(response: QlResourceNodeRootQuery$data) => {
        if (response?.resourceNodeByRevision) {
          return (
            <CpPatientSessionResourcecRedirect
              rootRevisionId={response.resourceNodeByRevision?.rootRevisionId}
              selectedRevisionId={resourceNode}
            />
          )
        }
        return null
      }}
    </QlResourceNodeRoot>
  )
}

export default CpPatientSessionRedirect
