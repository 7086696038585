import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Linking, ScrollView, View } from "react-native";
import CpAdSlot, { AdPlacement } from "components/common/CpAdSlot";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpGrid from "components/common/CpGrid";
import CpViewSessionSelect from "components/patientSession/CpViewSessionSelect";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import QlViewPatientSession from "query/QlViewPatientSession";
import { ScreenSizes } from "services/Constants";
import { useBackendSupportedLocale } from "services/Intl";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  bottomButtonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  container: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
const localTranslations = defineMessages({
  learnMore: {
    id: "qU0QGP",
    defaultMessage: "Learn more on IMD Health"
  },
  validSession: {
    id: "exVzEV",
    defaultMessage: "A valid Patient Session is required to view this page. You can return to IMD Health using the button below."
  }
});

/**
 * Fetches a PatientSession and displays it.
 */
const CtViewSession = ({
  isCcRecipient,
  patientSessionId
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    appConfig
  } = useImdSession();
  const {
    isMediumScreen,
    isNarrowScreen
  } = useScreenLayout();
  const locale = useBackendSupportedLocale(); // Find the correct ads for the screen. Splitting them out to ensure ads show at the correct non-widescreen sizes
  // i.e. larger leaderboard for medium screens, smaller leaderboard for smaller screens

  const viewPatientSessionAdSmall = useMemo(() => {
    return appConfig?.appAdvertisement?.adSpots?.find(ad => ad.placement === AdPlacement.ViewPatientSession && (ad.screenWidthIs.includes(ScreenSizes.IsMediumOrSmallerScreen) || ad.screenWidthIs.includes(ScreenSizes.IsNarrowScreen)));
  }, [appConfig?.appAdvertisement?.adSpots]);
  const viewPatientSessionAdMedium = useMemo(() => {
    return appConfig?.appAdvertisement?.adSpots?.find(ad => ad.placement === AdPlacement.ViewPatientSession && ad.screenWidthIs.includes(ScreenSizes.IsMediumScreen));
  }, [appConfig?.appAdvertisement?.adSpots]); // Memoize display logic to ensure ads show/hide if screen size changes

  const shouldDisplayAdSmall = useMemo(() => !appConfig?.appAdvertisement?.hideAds && isNarrowScreen, [appConfig?.appAdvertisement?.hideAds, isNarrowScreen]);
  const shouldDisplayAdMedium = useMemo(() => !appConfig?.appAdvertisement?.hideAds && isMediumScreen, [appConfig?.appAdvertisement?.hideAds, isMediumScreen]);
  const navigateToPublicApp = useCallback(() => {
    const url = "https://www.imdhealth.app/";
    Linking.openURL(url); // Do we want to track this event?  If so, what kind of event is it?
  }, []);
  const [pageHeader, setPageHeader] = useState(translations.screens[Routes.view_session]);
  const handleUpdateHeader = useCallback(header => setPageHeader(header), []);
  const queryVariables = useMemo(() => ({
    id: patientSessionId,
    // Because GraphQL resolves booleans to strings, we need to coerce this value to and from Boolean-String on either end. If we can get Booleans to works I'd be a lot happier about this
    isCcRecipient,
    locale
  }), [isCcRecipient, locale, patientSessionId]);
  return <ScrollView contentContainerStyle={rnStyles.flexSingle}>
      <main>
        <CpBackgroundHeader childStyle={styles.flexSingle} header={pageHeader}>
          <QlViewPatientSession variables={queryVariables}>
            {response => response.patientSession ? <_StyledCpGrid $_css={localStyles.container}>
                  <CpViewSessionSelect onUpdateHeader={handleUpdateHeader} pageHeader={pageHeader} patientSession={response.patientSession} />

                  <_StyledView $_css2={styles.row}>
                    <_StyledPrButton accessibilityHint={formatMessage(translations.accessibility.hint.openInNewTab, {
                baseHint: formatMessage(translations.accessibility.hint.navHeaderImdButton)
              })} onPress={navigateToPublicApp} testID="CtViewSession-publicAppLink" type="raised" $_css3={[styles.marginTopDouble, isNarrowScreen ? styles.fullWidth : undefined]}>
                      <_StyledPrText children={localTranslations.learnMore} $_css4={localStyles.bottomButtonText} />
                    </_StyledPrButton>
                  </_StyledView>

                  {
              /* Ensuring ads swap by explicitly rendering both for different screen sizes */
            }
                  {viewPatientSessionAdSmall && shouldDisplayAdSmall && <_StyledView2 $_css5={styles.paddingVerticalDouble}>
                      <CpAdSlot adManagerId={viewPatientSessionAdSmall.adManagerId} adUnit={viewPatientSessionAdSmall.adUnitId} slot={viewPatientSessionAdSmall.slot} />
                    </_StyledView2>}
                  {viewPatientSessionAdMedium && shouldDisplayAdMedium && <_StyledView3 $_css6={styles.paddingVerticalDouble}>
                      <CpAdSlot adManagerId={viewPatientSessionAdMedium.adManagerId} adUnit={viewPatientSessionAdMedium.adUnitId} slot={viewPatientSessionAdMedium.slot} />
                    </_StyledView3>}
                </_StyledCpGrid> : <View>
                  <PrText children={localTranslations.validSession} />
                  <_StyledPrButton2 onPress={navigateToPublicApp} type="raised" $_css7={styles.marginTopDouble}>
                    <_StyledPrText2 children={localTranslations.learnMore} $_css8={localStyles.bottomButtonText} />
                  </_StyledPrButton2>
                </View>}
          </QlViewPatientSession>
        </CpBackgroundHeader>
      </main>
    </ScrollView>;
};

export default CtViewSession;

var _StyledCpGrid = _styled(CpGrid).withConfig({
  displayName: "CtViewSession___StyledCpGrid",
  componentId: "sc-1b7ua7y-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CtViewSession___StyledView",
  componentId: "sc-1b7ua7y-1"
})(["", ""], p => p.$_css2);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CtViewSession___StyledPrButton",
  componentId: "sc-1b7ua7y-2"
})(["", ""], p => p.$_css3);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CtViewSession___StyledPrText",
  componentId: "sc-1b7ua7y-3"
})(["", ""], p => p.$_css4);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CtViewSession___StyledView2",
  componentId: "sc-1b7ua7y-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CtViewSession___StyledView3",
  componentId: "sc-1b7ua7y-5"
})(["", ""], p => p.$_css6);

var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CtViewSession___StyledPrButton2",
  componentId: "sc-1b7ua7y-6"
})(["", ""], p => p.$_css7);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CtViewSession___StyledPrText2",
  componentId: "sc-1b7ua7y-7"
})(["", ""], p => p.$_css8);