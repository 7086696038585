/**
 * @generated SignedSource<<93f1f2aaf7bf2716b85704c9a7f658a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QlResourceNodeRootQuery$variables = {
  revisionId?: string | null;
  rootRevisionId: string;
};
export type QlResourceNodeRootQuery$data = {
  readonly resourceNodeByRevision: {
    readonly rootRevisionId: string;
  } | null;
};
export type QlResourceNodeRootQuery = {
  response: QlResourceNodeRootQuery$data;
  variables: QlResourceNodeRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "revisionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rootRevisionId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "revisionId",
    "variableName": "revisionId"
  },
  {
    "kind": "Variable",
    "name": "rootRevisionId",
    "variableName": "rootRevisionId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootRevisionId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QlResourceNodeRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ResourceNode",
        "kind": "LinkedField",
        "name": "resourceNodeByRevision",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QlResourceNodeRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ResourceNode",
        "kind": "LinkedField",
        "name": "resourceNodeByRevision",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0c237c25c7c1130aa94059fd8ade383",
    "id": null,
    "metadata": {},
    "name": "QlResourceNodeRootQuery",
    "operationKind": "query",
    "text": "query QlResourceNodeRootQuery(\n  $rootRevisionId: String!\n  $revisionId: String\n) {\n  resourceNodeByRevision(rootRevisionId: $rootRevisionId, revisionId: $revisionId) {\n    rootRevisionId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6634ccdae9813dbdeabc094d71f6d0e8";

export default node;
