/**
 * @generated SignedSource<<537fab4eab91913b098a9739826a45fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type QlViewPatientSessionQuery$variables = {
  id: string;
  isCcRecipient?: boolean | null;
  locale?: Locale | null;
};
export type QlViewPatientSessionQuery$data = {
  readonly patientSession: {
    readonly children: ReadonlyArray<{
      readonly annotation: string | null;
      readonly icon: string | null;
      readonly names: any | null;
      readonly redemptionTime: string | null;
      readonly resourceNodeRevisionId: string;
      readonly rootNames: any | null;
      readonly rootResourceNodeRevisionId: string;
      readonly topicNodeRevisionId: string | null;
    }>;
    readonly completed: number | null;
    readonly duration: number;
    readonly fromPackage: boolean | null;
    readonly isPsp: boolean | null;
    readonly locale: Locale;
    readonly organizationId: string;
    readonly organizationLogo: string | null;
    readonly organizationName: string;
    readonly practitionerName: string | null;
    readonly started: string;
  } | null;
};
export type QlViewPatientSessionQuery = {
  response: QlViewPatientSessionQuery$data;
  variables: QlViewPatientSessionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isCcRecipient"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "isCcRecipient",
    "variableName": "isCcRecipient"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "annotation",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v4 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "redemptionTime",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "rootNames",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootResourceNodeRevisionId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topicNodeRevisionId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fromPackage",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPsp",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "organizationLogo",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "practitionerName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "started",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QlViewPatientSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PatientSession",
        "kind": "LinkedField",
        "name": "patientSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PatientSessionResourceNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QlViewPatientSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PatientSession",
        "kind": "LinkedField",
        "name": "patientSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PatientSessionResourceNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79f19cb5c44f4b3d3a8418f16791010a",
    "id": null,
    "metadata": {},
    "name": "QlViewPatientSessionQuery",
    "operationKind": "query",
    "text": "query QlViewPatientSessionQuery(\n  $id: String!\n  $isCcRecipient: Boolean\n  $locale: Locale\n) {\n  patientSession(id: $id, isCcRecipient: $isCcRecipient) {\n    children {\n      annotation\n      icon(locale: $locale)\n      names(locale: $locale)\n      redemptionTime\n      resourceNodeRevisionId\n      rootNames(locale: $locale)\n      rootResourceNodeRevisionId\n      topicNodeRevisionId\n      id\n    }\n    completed\n    duration\n    fromPackage\n    locale\n    isPsp\n    organizationId\n    organizationLogo(locale: $locale)\n    organizationName\n    practitionerName\n    started\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec2fef6afadabbbd1276b441b049cc75";

export default node;
